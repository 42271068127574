import {
  Icon,
  LinkButton,
  SkeletonDots,
  Text,
  useIsMobile
} from '@yes.technology/react-toolkit'

import NavigationSliderSecondary from 'component/NavigationSliderSecondary'
import {
  BulletPoints,
  ButtonWrapper,
  Complement,
  Container,
  Content,
  ContentMiddle,
  ContentText,
  Image,
  ImageWrapper,
  Navigation,
  NavigationSliders,
  NavigationWrapper
} from './DynamicInfoCardHighlight.styles'
import { DynamicInfoCardHighlightProps } from './DynamicInforCardHighlight.types'

export default function DynamicInfoCardHighlight({
  navigationHeading,
  navigationSliderLabels,
  des,
  image,
  bulletPoints,
  text,
  complement,
  callToAction,
  isLoading = false,
  showNavigationSliders = true,
  onClickNavigationSlider,
  mainNavigationColor = '#6F7AA7',
  secondaryNavigationColor = '#FBC521',
  buttonPosition = 'bottom',
  complementPosition = 'bottom',
  secondaryNavigationDisabled,
  imagePosition,
  imageFormat = 'default',
  bulletPointIconColor,
  alignText
}: DynamicInfoCardHighlightProps) {
  const [mainNavigationLabel, secondaryNavigationLabel] = navigationSliderLabels
  const isMobile = useIsMobile()

  const button = callToAction && (
    <LinkButton
      href={callToAction.href}
      disabled={isLoading || callToAction.disabled}
      heightClass={`${isMobile ? 'semiLarge' : 'large'}`}
      style={callToAction.style}
    >
      {!isLoading ? callToAction.label : <SkeletonDots />}
    </LinkButton>
  )

  return (
    <Container>
      {showNavigationSliders && (
        <Navigation>
          <NavigationWrapper>
            <Text variant='content-emphasis-06' as='h2'>
              {navigationHeading}
            </Text>
            <NavigationSliders>
              <NavigationSliderSecondary
                currentIndex={0}
                options={[
                  {
                    value: mainNavigationLabel,
                    color: mainNavigationColor
                  }
                ]}
                onPrevClick={() => onClickNavigationSlider('main-previous')}
                onNextClick={() => onClickNavigationSlider('main-next')}
              />
              {secondaryNavigationLabel && (
                <NavigationSliderSecondary
                  currentIndex={0}
                  options={[
                    {
                      value: secondaryNavigationLabel,
                      color: secondaryNavigationColor
                    }
                  ]}
                  onPrevClick={() =>
                    onClickNavigationSlider('secondary-previous')
                  }
                  onNextClick={() => onClickNavigationSlider('secondary-next')}
                  disabled={secondaryNavigationDisabled}
                />
              )}
            </NavigationSliders>
          </NavigationWrapper>
        </Navigation>
      )}
      <Content>
        {isLoading && <SkeletonDots />}
        {!isLoading && (
          <>
            <h2>{des}</h2>

            {complementPosition === 'top' && !isMobile && complement && (
              <Complement>{complement}</Complement>
            )}

            <ContentMiddle imagePosition={imagePosition}>
              <ImageWrapper imagePosition={imagePosition}>
                <Image imageFormat={imageFormat}>
                  <img {...image} />
                </Image>
              </ImageWrapper>

              {complementPosition === 'top' && isMobile && complement && (
                <Complement>{complement}</Complement>
              )}

              {bulletPoints && (
                <BulletPoints imagePosition={imagePosition}>
                  {bulletPoints.map((bulletPoint) => (
                    <li key={bulletPoint}>
                      <Icon
                        iconName='Success'
                        {...(bulletPointIconColor
                          ? { hexColorCode: bulletPointIconColor }
                          : { icColor: 'primary' })}
                      />
                      <ContentText>{bulletPoint}</ContentText>
                    </li>
                  ))}
                </BulletPoints>
              )}

              {text && (
                <ContentText
                  imagePosition={imagePosition}
                  alignText={alignText}
                >
                  {text}
                </ContentText>
              )}

              {buttonPosition === 'right' && !isMobile && (
                <ButtonWrapper imagePosition={imagePosition}>
                  {button}
                </ButtonWrapper>
              )}
            </ContentMiddle>

            {complementPosition === 'bottom' && complement && (
              <Complement>{complement}</Complement>
            )}
          </>
        )}

        {(buttonPosition === 'bottom' || isMobile) && button}
      </Content>
    </Container>
  )
}
