import { isEmbedded } from '@yes.technology/react-toolkit'
import { loginSucceeded } from 'authentication/Login/ducks/authentication'
import jwtDecode from 'jwt-decode'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { IframeAttributes } from 'shared/components/Iframe'
import { getApplicationSystemOwner } from 'shared/utils'

type OriginalSender = {
  source: string
}

type EventDataMessage = {
  height?: string
  type?: string
  url?: string
  token?: string
  expirationDate?: number
  originalSender: OriginalSender
}

type Props = {
  origin: string
  src: string
  title?: string
  allow?: string
  initialHeight?: string
  sharedState?: Record<string, any>
}

const useIframe = ({
  origin,
  src,
  title = 'Masterdata',
  allow = 'camera;geolocation',

  initialHeight = '800px', //  TODO: Alterar para 100vh quando a workflow engine passar a enviar o height por mensagem
  sharedState
}: Props) => {
  const [height, setHeight] = useState(initialHeight)
  const [iframeAttributes, setIframeAttributes] = useState<
    IframeAttributes | undefined
  >()

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const receiveMessage = useCallback(
    (event: MessageEvent<EventDataMessage>) => {
      // TODO: Improve iframe recognition in case of two different iframes with same origin (URL =/= Origin)
      if (!origin.startsWith(event.origin)) return false
      const { height, type, originalSender } = event.data
      if (height) {
        setHeight(`${Math.ceil(Number(height) + 50)}`)
      }
      if (type === 'redirect') {
        if (isEmbedded()) {
          postMessage(event.data)
        } else {
          const absoluteUrlRegex = new RegExp('^(?:[a-z+]+:)?//', 'i')
          const { url } = event.data
          if (url) {
            absoluteUrlRegex.test(url)
              ? (window.location.href = url)
              : navigate(url)
          }
        }
      }

      if (type === 'updateAuthToken') {
        const { token, expirationDate } = event.data
        if (!token || !expirationDate) return

        const decoded = jwtDecode<{ email: string }>(token)
        dispatch(
          loginSucceeded(decoded.email, {
            token,
            expiration_date: expirationDate,
            tokenType: 'private'
          })
        )

        if (isEmbedded()) {
          postMessage(event.data)
        }
      }

      if (type === 'getSharedState') {
        event.source?.postMessage(
          {
            originalSender,
            type: 'patchSharedState',
            sharedState: { ...sharedState, ...getApplicationSystemOwner() }
          },
          { targetOrigin: '*' }
        )
      }
    },
    [dispatch, origin, sharedState]
  )

  useEffect(() => {
    setIframeAttributes({
      src,
      height,
      title,
      allow
    })
  }, [allow, height, src, title])

  return {
    iframeAttributes,
    receiveMessage
  }
}

export default useIframe
