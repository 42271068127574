import {
  DatePicker,
  Field,
  Unifree,
  YesObject,
  datepickerToISOFormat
} from '@yes.technology/react-toolkit'
import { useTranslation } from 'react-i18n-lite'
import { MODIFIABLE_FIELDS_DENYLIST } from 'shared/constants'
import formatDate from 'shared/utils/date'
import { AllowedLocales } from 'shared/utils/date/formatDate'

export interface FieldWithColumns extends Field {
  columns?: string[]
}

export type FieldsProps = {
  fields: FieldWithColumns[]
  fieldValues?: Record<string, string | YesObject[]>
  informational: boolean
  onChange: (fieldColumn: string, value: string) => void
  isEditing?: boolean
}

const isUnmodifiableField = (field: Field) =>
  MODIFIABLE_FIELDS_DENYLIST.includes(field.column_json)

const Fields = ({
  fields,
  fieldValues = {},
  informational,
  onChange,
  isEditing
}: FieldsProps) => {
  const { language } = useTranslation()

  const getFieldAttributeValue = (field: Field) => {
    const fieldAttributeValue = informational || isUnmodifiableField(field)

    return isEditing
      ? {
          disabled: fieldAttributeValue
        }
      : {
          informational: fieldAttributeValue
        }
  }

  return (
    <div className='row mt-2 mb-2'>
      {fields.map((field) => {
        let displayedValue = fieldValues[field.column_json] || ''
        if (field.type === 'DATETIME' && fieldValues[field.column_json]) {
          displayedValue = formatDate(
            fieldValues[field.column_json] as string,
            language as AllowedLocales
          )
        }
        if (field.type === 'DATETIME' && !informational) {
          return (
            <div
              key={field.uuid}
              className={`${field.columns ? field?.columns?.join(' ') : 'col-md-3 position-relative'}`}
            >
              <DatePicker
                onChange={(dateTime) =>
                  onChange(
                    field.column_json,
                    datepickerToISOFormat(
                      dateTime || '',
                      language as AllowedLocales
                    ) || ''
                  )
                }
                heightClass='medium'
                format='date-time'
                lang={language}
                value={typeof displayedValue === 'string' ? displayedValue : ''}
                placeholder={field.display_text.des}
                label={field.display_text.des}
                {...getFieldAttributeValue(field)}
              />
            </div>
          )
        }
        return (
          <div
            key={field.uuid}
            className={`${field.columns ? field?.columns?.join(' ') : 'col-md-3'}`}
          >
            <Unifree
              label={field.display_text.des}
              value={displayedValue as string}
              required
              onChange={(value) => onChange(field.column_json, value)}
              {...getFieldAttributeValue(field)}
            />
          </div>
        )
      })}
    </div>
  )
}

export default Fields
