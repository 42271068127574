import { CollapsibleCard } from '@yes.technology/react-toolkit'
import { useTranslation } from 'react-i18n-lite'
import { useStatusActions } from 'status/shared'
import useStatusByCode from 'status/shared/hooks/useStatusByCode'
import { useFetchYesObjects } from 'yesObject/shared'
import useYesObject from 'yesObject/shared/hooks/useYesObject'
import { Line, SectionTitle, Title } from './TransportOrderCard.styles'
import CheckpointsSection from './components/CheckpointsSection/CheckpointsSection'
import RouteSection from './components/RouteSection/RouteSection'
import StatusActions from './components/StatusActions'
import { Checkpoint, Route, TransportOrder } from './types'

function TransportOrderCard(transportOrder: TransportOrder) {
  const { t } = useTranslation()

  const { uuid_status: statusCode } = transportOrder

  const { status } = useStatusByCode({
    objectclassUuid: window.uuidObjectclassTransportorder,
    statusCode
  })

  const { statusActions } = useStatusActions({
    statuses: status ? [status] : []
  })

  const { yesObject: route } = useYesObject<Route>({
    objectUUID: transportOrder.route?.[0].uuid || '',
    objectclassUUID: window.uuidObjectclassRoute
  })

  const { yesObjects: checkpoints }: { yesObjects: Checkpoint[] } =
    useFetchYesObjects({
      filterBody: {
        objectclass: window.uuidObjectclassCheckpoint,
        query: {
          $and: [{ 'uuid.des': { $in: route?.checkpoint?.map((c) => c.uuid) } }]
        }
      },
      enabled: !!route?.checkpoint?.[0].uuid
    })

  return (
    <CollapsibleCard
      heightClass='doubleXSmall'
      headerColor='white'
      centerHeaderElement={<Title>{transportOrder.des}</Title>}
    >
      <Line />
      <StatusActions
        statusActions={statusActions}
        transportOrder={transportOrder}
      />

      <CollapsibleCard
        hierarchyCardLevel={1}
        heightClass='doubleXSmall'
        style={{ marginBottom: 8 }}
        headerColor='blue'
        centerHeaderElement={
          <SectionTitle>{t('transport-card.route')}</SectionTitle>
        }
      >
        <RouteSection route={route} checkpoints={checkpoints} />
      </CollapsibleCard>

      <CheckpointsSection checkpoints={checkpoints} />
    </CollapsibleCard>
  )
}

export default TransportOrderCard
