const CLASSES_MAP: Record<string, string> = {
  GenericObjectCard: 'col-md-6 col-xl-3 mt-2 mb-2',
  TaskObjectCard: 'col-md-6 col-lg-4 col-xl-3 ',
  ContactObjectCard: 'col-md-6 col-xl-3'
}

type CardContainerProps = {
  children: JSX.Element
  cardType: string
  previewMode?: boolean
  cardHeight?: string
}

function CardContainer({
  children,
  cardType,
  previewMode,
  cardHeight
}: CardContainerProps) {
  return (
    <div
      className={previewMode ? 'col-12' : CLASSES_MAP[cardType]}
      aria-label='Card Container'
      style={{ height: cardHeight }}
    >
      {children}
    </div>
  )
}

export default CardContainer
