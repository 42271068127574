import {
  Pagination as PaginationType,
  defaultTheme
} from '@yes.technology/react-toolkit'
import React from 'react'

import { useTranslation } from 'react-i18n-lite'
import Pagination from '../Pagination'
import SectionDivider from '../SectionDivider'
import { AreaLineCenter } from './DataTable.styles'
import ColumnsNavigation from './components/ColumnsNavigation'
import LayoutAndReport from './components/LayoutAndReport/LayoutAndReport'
import { HierarchyLevel } from './types'

type DataTableAreaLineProps = {
  navigationSliderBackgroundColor?: string
  navigationSliderColor?: string
  pagination?: PaginationType
  setCurrentPage?: (page: number) => void
  hierarchyLevel: HierarchyLevel
  title: string
  isShowTitle?: boolean
  isCollapsibleCard?: boolean
  titleOnly?: boolean
  children?: React.ReactNode
  embedded?: boolean
  disabled?: boolean
  hideMainCollapsibleContainer?: boolean
  allowCollapsibleNavigationCardCustomColors?: boolean
  isShowReports?: boolean
} & React.HTMLAttributes<HTMLDivElement>

const primaryColor = (defaultTheme as any).colors.primary || '#006bff'

export default function DataTableAreaLine({
  navigationSliderColor,
  navigationSliderBackgroundColor = '#004099',
  pagination,
  hierarchyLevel,
  title,
  isShowTitle = true,
  isCollapsibleCard = false,
  titleOnly,
  children,
  setCurrentPage = () => '',
  embedded,
  hideMainCollapsibleContainer = false,
  allowCollapsibleNavigationCardCustomColors = false,
  isShowReports,
  ...htmlProps
}: DataTableAreaLineProps) {
  const { t } = useTranslation()

  const completeTitle = isShowTitle
    ? title
      ? `${title} | ${t('area-title.data-tables')}`
      : t('area-title.data-tables')
    : ''

  const getNavigationColor = () => {
    if (isCollapsibleCard) {
      return allowCollapsibleNavigationCardCustomColors
        ? navigationSliderColor
        : undefined
    }
    return navigationSliderColor
  }

  const getNavigationBackgroundColor = () => {
    if (isCollapsibleCard) {
      return allowCollapsibleNavigationCardCustomColors
        ? navigationSliderBackgroundColor
        : primaryColor
    }
    return navigationSliderBackgroundColor
  }

  const color = getNavigationColor()

  const backgroundColor = getNavigationBackgroundColor()

  const centerHeaderElement = titleOnly ? undefined : (
    <AreaLineCenter aria-label='Area line center'>
      <ColumnsNavigation
        hierarchyLevel={hierarchyLevel}
        navigationSliderBackgroundColor={backgroundColor}
        navigationSliderColor={color}
      />
      <Pagination
        sectionText='Página'
        pagination={pagination}
        setCurrentPage={setCurrentPage}
        hierarchyLevel={hierarchyLevel}
        className='d-flex justify-content-lg-end flex-column flex-lg-row'
        navigationSliderBackgroundColor={backgroundColor}
        navigationSliderColor={color}
      />
    </AreaLineCenter>
  )

  const rightHeaderElement = isShowReports ? <LayoutAndReport /> : undefined

  return (
    <SectionDivider
      title={completeTitle}
      isCollapsibleCard={isCollapsibleCard}
      centerHeaderElement={centerHeaderElement}
      rightHeaderElement={rightHeaderElement}
      backgroundColor={backgroundColor}
      color={color}
      embedded={embedded}
      isVisible={!hideMainCollapsibleContainer}
      {...htmlProps}
    >
      {children}
    </SectionDivider>
  )
}
