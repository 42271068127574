import { datepickerToISOFormat } from '@yes.technology/react-toolkit'

type DatePickerFormatParams = Parameters<typeof datepickerToISOFormat>

export type AllowedLocales = DatePickerFormatParams[1]

const defaultFormatOptions: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  timeZone: 'UTC'
}

const formatDate = (
  date: string,
  language: AllowedLocales = 'pt-BR',
  formatOptions?: Intl.DateTimeFormatOptions
) => {
  const options = formatOptions || defaultFormatOptions
  return new Date(date)
    .toLocaleDateString(language, options)
    .replaceAll(', ', ' ')
}

export default formatDate
