import useStyleMaps, { StyleMap } from 'site/Renderer/hooks/useStyleMap'
import { StyledContainer } from '../DynamicInfoCardHighlightContainer/DynamicInfoCardHighlight.style'
import DynamicInfoCardHighlight from 'component/DynamicInfoCardHighlight'
import { SafeHtmlContent } from '@yes.technology/react-toolkit'

type Props = {
  props: {
    uuid: string
    des: string
    complement?: string
    image: {
      src: string
      alt: string
    }
    text?: string
    styleMap?: StyleMap
  }
}

const DynamicInfoCardHighlightSimplifiedContainer = ({ props }: Props) => {
  const { styleMap, des, complement, image, text } = props

  const styleProps = useStyleMaps(styleMap)
  return (
    <StyledContainer $isLoading={false} style={styleProps}>
      <DynamicInfoCardHighlight
        navigationHeading={''}
        navigationSliderLabels={['']}
        des={<SafeHtmlContent html={des} />}
        complement={complement && <SafeHtmlContent html={complement} />}
        onClickNavigationSlider={() => null}
        image={image}
        text={text && <SafeHtmlContent html={text} />}
        showNavigationSliders={false}
        isLoading={false}
        alignText={'center'}
      />
    </StyledContainer>
  )
}

export default DynamicInfoCardHighlightSimplifiedContainer
