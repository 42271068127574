import { CSSProperties } from 'react'
import { Optional, Record, String, Unknown } from 'runtypes'
import { StyleMap } from 'site/Renderer/hooks/useStyleMap'

export type SupportBannerContainerProps = {
  props: {
    des: string
    buttonLabel: string
    buttonHref: string
    buttonStyle?: CSSProperties
    leftBackgroundImageUrl?: string
    rightBackgroundImageUrl?: string
    styleMap?: StyleMap
  }
}

export type LinkWrapper = {
  children?: React.ReactNode
  url: string
}

export const SupportBannerContainerTypeGuard = Record({
  des: String,
  buttonLabel: String,
  buttonHref: String,
  buttonStyle: Optional(Unknown),
  leftBackgroundImageUrl: Optional(String),
  rightBackgroundImageUrl: Optional(String),
  styleMap: Optional(Unknown)
})

export type SupportBannerProps = {
  leftBackgroundImageUrl?: string
  rightBackgroundImageUrl?: string
  des: React.ReactNode
  button: {
    label: string
    href: string
    style?: CSSProperties
  }
  style?: React.CSSProperties
}
