import styled from 'styled-components'

import { TaskPriority } from './TaskObjectCard.types'

const backgroundByPriority: Record<TaskPriority, string> = {
  low: '#ABBED1',
  normal: '#60B878',
  high: '#DC890D',
  urgent: '#A72121'
}

export const PriorityBadge = styled.div<{ priority: TaskPriority }>`
  position: absolute;
  top: -5px;
  right: 8px;
  width: 46px;
  height: 12px;
  line-height: 12px;
  font-size: 8px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  color: ${({ priority }) => (priority === 'urgent' ? '#FFFFFF' : '#323232')};
  background: ${({ priority }) => backgroundByPriority[priority]};
`

export const Container = styled.div`
  position: relative;
  width: 100%;

  h2 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    margin: 0;
  }

  hr {
    color: #f7f8fa;
    height: 2px;
    margin: 0;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Description = styled.div`
  font-size: 12px;
  line-height: 16px;
`

export const TaskActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
`

export const TaskActionsRow = styled.div`
  display: flex;
  gap: 6px;
  flex: 1 0 40%;
  width: 100%;

  &:first-child,
  &:last-child:nth-child(even) {
    flex: auto;
  }
`

export const FieldsRow = styled.div`
  display: grid;
  gap: 8px;
  grid-auto-flow: column;
`

export const UserBlocks = styled.div`
  display: flex;
  gap: 16px;
`

export const UserBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 8px;
  line-height: 12px;
`

export const UserAvatar = styled.div`
  display: flex;
  gap: 8px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  align-items: center;
`

export const StyledField = styled.div`
  label {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }
`
