import { SkeletonDots } from '@yes.technology/react-toolkit'
import React from 'react'
import styled from 'styled-components'
import { Mode } from './hooks/useTableContext'

const TableHeaderColumnBlock = ({
  children,
  ...thProps
}: React.HTMLAttributes<HTMLTableCellElement>) => (
  <th {...thProps}>
    <span>{children}</span>
  </th>
)

export const TableHeaderColumn = styled(TableHeaderColumnBlock)<{ mode: Mode }>`
  white-space: nowrap;

  ${({ mode }) =>
    mode === 'view'
      ? `
    && {
      padding: 0.5rem 0;
    }

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }

    > span {
      display: flex;
      padding-bottom: 0.5rem;
      border-bottom: 2px solid #abbed1;
    }
  `
      : 'padding-bottom: 0 !important;'}
`

export const DataTableSkeletonDots = styled(SkeletonDots).attrs({ size: 3 })`
  height: 1em;
  align-items: flex-end;
  color: inherit;

  & > div {
    background-color: currentColor;
  }
`

export const AreaLineCenter = styled.div`
  @media (min-width: 768px) {
    display: flex;
  }
`
