import {
  Button,
  DocumentListCard,
  GenericObjectCard,
  SkeletonDots
} from '@yes.technology/react-toolkit'

import { CardContainerProps, ObjectsTypes } from './CardContainer.types'
import { useImageDocument } from 'shared/hooks'
import { StyledContainer } from './styles'
import { SafeHtmlContent } from 'site/shared/components/SafeHtmlContent'
import { useSiteState } from 'siteState/shared'
import { createQueryString } from 'shared/utils'
import useStyleMaps from 'site/Renderer/hooks/useStyleMap'
import { useTranslation } from 'react-i18n-lite'

type CardItemProps = {
  object: ObjectsTypes
  link: string
  linkParams?: []
  buttonProps?: React.ComponentProps<typeof Button>
  buttonLabel?: string
  imageProps?: React.HTMLProps<HTMLImageElement>
  imageHeight?: string
  imageFormat?: 'circle' | 'default'
}

interface RowRendererProps {
  objects: CardItemProps['object'][]
  justifyContent?: 'start' | 'end' | 'center'
  link: CardItemProps['link']
  linkParams?: CardItemProps['linkParams']
  buttonProps?: React.ComponentProps<typeof Button>
  buttonLabel?: string
  imageProps?: React.HTMLProps<HTMLImageElement>
  imageHeight?: string
  imageFormat?: 'circle' | 'default'
}

export function RowRenderer({
  objects,
  justifyContent = 'start',
  link,
  linkParams,
  buttonProps,
  buttonLabel,
  imageProps,
  imageHeight,
  imageFormat
}: RowRendererProps) {
  const chunkArray = (array: CardItemProps['object'][], size: number) => {
    const chunkedArr = []
    for (let i = 0; i < array?.length; i += size) {
      chunkedArr.push(array.slice(i, i + size))
    }
    return chunkedArr
  }

  const rows = chunkArray(objects, 4)

  return (
    <>
      {rows.map((row, rowIndex) => (
        <div
          key={`row-${rowIndex}`}
          className={`row justify-content-${justifyContent}`}
        >
          {row.map((object: CardItemProps['object']) => (
            <div key={object.uuid} className='col-xl-3 mb-3 mb-sm-4'>
              <CardItem
                {...{ object }}
                link={link}
                linkParams={linkParams}
                buttonProps={buttonProps}
                buttonLabel={buttonLabel}
                {...{ imageProps, imageHeight, imageFormat }}
              />
            </div>
          ))}
        </div>
      ))}
    </>
  )
}

const CardContainer = ({ props }: CardContainerProps) => {
  const styleProps = useStyleMaps(props.styleMap)

  const SkeletonCard = () => (
    <DocumentListCard>
      <SkeletonDots size={4} />
      <Button variant='secondary' className='mt-3'>
        <SkeletonDots size={4} />
      </Button>
    </DocumentListCard>
  )

  const SkeletonCardList = () => (
    <div className={`row justify-content-center`}>
      <div className='col-xl-3 mb-3 mb-sm-4 mt-3 mt-sm-4'>
        <SkeletonCard />
      </div>
    </div>
  )

  const [allResultsFetched] = useSiteState('allResultsFetched')
  const isLoading = !allResultsFetched
  return (
    <StyledContainer style={styleProps}>
      {!isLoading && (
        <RowRenderer
          objects={props.objects}
          justifyContent={props.justifyContent}
          link={props.link}
          linkParams={props.linkParams}
          buttonProps={props.button}
          buttonLabel={props?.buttonLabel}
          imageProps={props?.imageProps}
          imageHeight={props?.imageHeight}
          imageFormat={props?.imageFormat}
        />
      )}
      {isLoading && <SkeletonCardList />}
    </StyledContainer>
  )
}

export const CardItem = ({
  object,
  link,
  linkParams,
  buttonProps,
  buttonLabel,
  imageProps,
  imageHeight,
  imageFormat
}: CardItemProps) => {
  const { imageUrl, isLoading: isLoadingDocument } = useImageDocument({
    document: object?.document || ''
  })

  const { t } = useTranslation()

  return (
    <GenericObjectCard
      {...object}
      des={<SafeHtmlContent html={object.des} />}
      image={(!isLoadingDocument && imageUrl) || ''}
      frameImage={true}
      fullHeight
      {...{ imageProps, imageHeight, imageFormat }}
    >
      <a href={`${link}?${createQueryString(object, linkParams || [])}`}>
        <Button {...buttonProps} variant={buttonProps?.variant ?? 'secondary'}>
          {buttonLabel || t('card-container.learn-more')}
        </Button>
      </a>
    </GenericObjectCard>
  )
}

export default CardContainer
