import { Button } from '@yes.technology/react-toolkit'
import { TransportOrder } from 'component/TransportOrderCard/types'
import useKanbanContext from 'site/Renderer/components/Kanban/components/KanbanAutoColumns/useKanbanContext'
import { updateStatus } from 'status/shared'
import { StatusAction } from 'status/shared/situation.type'

type StatusActionsProps = {
  transportOrder: TransportOrder
  statusActions: StatusAction[]
}

export default function StatusActions({
  transportOrder,
  statusActions
}: StatusActionsProps) {
  const { onStatusChange } = useKanbanContext()

  const changeStatus = (destinationStatusCode: string) => {
    updateStatus({
      objectVersion: transportOrder.version,
      objectUuid: transportOrder.uuid,
      objectclassUuid: window.uuidObjectclassTransportorder,
      destinationStatusCode,
      showLoading: false
    })

    onStatusChange?.(transportOrder.uuid, destinationStatusCode)
  }

  return (
    <>
      {statusActions.map((statusAction) => (
        <Button
          key={statusAction.destination_status?.[0]?.uuid}
          variant='primary'
          heightClass='semiSmall'
          onClick={() =>
            changeStatus(statusAction.destination_status?.[0]?.code)
          }
          style={{ marginBottom: 8 }}
        >
          {statusAction.des}
        </Button>
      ))}
    </>
  )
}
