import { useCallback, useEffect, useRef, useState } from 'react'
import { Container, InnerContainer, SliderButton } from './Tabs.styles'
import { TabsProps } from './Tabs.types'

import { Icon } from '@yes.technology/react-toolkit'
import { useTranslation } from 'react-i18n-lite'
import TabItem from './TabItem'
import { tabsComponentSizes } from './Tabs.enum'

export default function Tabs({
  heightClass = 'regular',
  textColor = 'black',
  highlightColor,
  items,
  onItemSelect,
  resetStateOnItemsChange = true,
  disabled,
  focusable,
  showSeparators = true,
  ...divProps
}: TabsProps) {
  const innerContainer = useRef<HTMLDivElement>(null)
  const [selectedIndex, setSelectedIndex] = useState(0)

  const totalLines = Math.round(
    (innerContainer.current?.scrollHeight || 0) /
      tabsComponentSizes[heightClass].height
  )

  const [currentLine, setCurrentLine] = useState(
    (innerContainer.current?.scrollTop || 0) /
      tabsComponentSizes[heightClass].height
  )
  const { t } = useTranslation()

  useEffect(() => {
    if (!resetStateOnItemsChange) return

    setSelectedIndex(0)
    onItemSelect(items[0].des)
    setCurrentLine(0)
  }, [items, resetStateOnItemsChange, onItemSelect])

  useEffect(() => {
    setCurrentLine(0)
  }, [heightClass])

  const onItemSelected = useCallback(
    (index: number) => {
      setSelectedIndex(index)
      onItemSelect(items[index].des)
    },
    [onItemSelect, items]
  )

  const onPrevClick = useCallback(() => {
    if (!innerContainer.current) return
    const updatedCurrentLine =
      innerContainer.current.scrollTop / tabsComponentSizes[heightClass].height

    if (updatedCurrentLine > 0) {
      setCurrentLine(updatedCurrentLine - 1)
      innerContainer.current.scroll(
        0,
        innerContainer.current.scrollTop -
          tabsComponentSizes[heightClass].height
      )
    }
  }, [heightClass])

  const onNextClick = useCallback(() => {
    if (!innerContainer.current) return
    const updatedCurrentLine =
      innerContainer.current.scrollTop / tabsComponentSizes[heightClass].height

    if (updatedCurrentLine < totalLines - 1) {
      setCurrentLine(updatedCurrentLine + 1)
      innerContainer.current.scroll(
        0,
        innerContainer.current.scrollTop +
          tabsComponentSizes[heightClass].height
      )
    }
  }, [heightClass, totalLines])

  const previousLabel = `${items[selectedIndex].des} - ${t('navigation-slider.previous')}`
  const nextLabel = `${items[selectedIndex].des} - ${t('navigation-slider.next')}`
  const leftDisabled = currentLine === 0
  const rightDisabled = currentLine === totalLines - 1

  const showButtons = totalLines > 1

  return (
    <Container heightClass={heightClass} {...divProps}>
      {showButtons && (
        <SliderButton
          heightClass={heightClass}
          onClick={onPrevClick}
          type='button'
          aria-label={previousLabel}
          disabled={leftDisabled}
        >
          <Icon
            iconName='NavigationLeft'
            icSize='auto'
            icColor={leftDisabled ? 'Gray_0_3' : 'blackText'}
          />
        </SliderButton>
      )}
      <InnerContainer heightClass={heightClass} ref={innerContainer}>
        {items.map((tab, tabIndex) => (
          <TabItem
            key={tab.uuid}
            tab={tab}
            tabIndex={tabIndex}
            selectedIndex={selectedIndex}
            highlightColor={highlightColor}
            heightClass={heightClass}
            textColor={textColor}
            onItemSelected={onItemSelected}
            disabled={disabled}
            focusable={focusable}
            showSeparators={showSeparators}
          />
        ))}
      </InnerContainer>

      {showButtons && (
        <SliderButton
          onClick={onNextClick}
          type='button'
          aria-label={nextLabel}
          heightClass={heightClass}
          disabled={rightDisabled}
        >
          <Icon
            iconName='NavigationRight'
            icSize='auto'
            icColor={rightDisabled ? 'Gray_0_3' : 'blackText'}
          />
        </SliderButton>
      )}
    </Container>
  )
}
