import { CollapsibleCard, Grid } from '@yes.technology/react-toolkit'
import React from 'react'
import styled from 'styled-components'

export const CollapsibleContainer = styled(CollapsibleCard)<{
  backgroundColor?: string
  color?: string
  centerHeaderElement?: React.ReactElement
  embedded?: boolean
}>`
  & > :first-child {
    border: 0px;

    > nav {
      border-radius: 0 !important;
      ${({ backgroundColor }) =>
        backgroundColor && `background-color: ${backgroundColor};`}
      margin-bottom: .5rem;
      padding: 0px 1rem;

      @media (max-width: 768px) {
        min-height: 48px;
        height: auto;
        align-content: center;
      }

      @media (min-width: 1280px) {
        padding: 0px 1.5rem;
      }

      @media (min-width: 1400px) {
        padding: 0px 2rem;
      }

      @media (min-width: 768px) {
        > div > div {
          ${({ centerHeaderElement }) =>
            !!centerHeaderElement && `width: 33.333%;`}
        }
      }

      > div * {
        ${({ color }) => color && `color: ${color};`}
      }

      > div {
        @media (max-width: 767px) {
          flex-wrap: wrap;
        }
      }
    }

    > div {
      padding: 0px 1rem;
      margin-bottom: 0.5rem;

      @media (min-width: 1280px) {
        padding: 0px 1.5rem;
      }

      @media (min-width: 1400px) {
        padding: 0px 2rem;
      }

      ${({ embedded }) => embedded && ``}
    }
  }
`

export const ActionsContainer = styled.div`
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const StyledGrid = styled(Grid)`
  width: 100%;
  margin: 16px auto 0 auto;

  @media (min-width: 768px) {
    max-width: 1920px;
  }
`

export const CollapsibleAreaLine = styled(CollapsibleCard)<{
  backgroundColor?: string
  color?: string
  centerHeaderElement?: React.ReactElement
  embedded?: boolean
}>`
  > :first-child {
    > nav {
      ${({ backgroundColor }) =>
        backgroundColor && `background-color: ${backgroundColor};`}

      > div * {
        ${({ color }) => color && `color: ${color};`}
      }
    }
  }
`
