import { useMemo } from 'react'
import { TableContextValue } from './useTableContext'

const useTableContextValue = ({
  idObjectClass,
  objectclass,
  fields,
  actionColumns,
  actionColumnsPosition,
  columnsNames,
  objects,
  refetchObjects,
  columnsLayouts,
  onSort,
  sortOptions,
  currentColumnIndex,
  totalColumns,
  amountOfFixedColumns,
  pagination,
  totalObjects,
  layoutClassificationUuid,
  hierarchyLevel,
  actionButton,
  selectedRows,
  setSelectedRows,
  mode,
  setMode,
  changedFieldValues,
  setChangedFieldValues,
  relationCounterpartField,
  reportDownloadTriggered,
  triggerReportDownload,
  reportUrl,
  objectclassQueryString,
  displayLayoutSelection,
  informational,
  embedded,
  navigationSliderBackgroundColor,
  navigationSliderColor,
  concatFields,
  hideRowActionButtons,
  hideSortButtons,
  onChangeColumnsPage,
  amountOfActionColumns,
  customActionButtons
}: TableContextValue): TableContextValue => {
  return useMemo(
    () => ({
      idObjectClass,
      objectclass,
      fields,
      actionColumns,
      actionColumnsPosition,
      columnsNames,
      objects,
      refetchObjects,
      columnsLayouts,
      onSort,
      sortOptions,
      currentColumnIndex,
      totalColumns,
      pagination,
      totalObjects,
      layoutClassificationUuid,
      hierarchyLevel,
      actionButton,
      selectedRows,
      setSelectedRows,
      mode,
      setMode,
      changedFieldValues,
      setChangedFieldValues,
      relationCounterpartField,
      reportDownloadTriggered,
      triggerReportDownload,
      reportUrl,
      objectclassQueryString,
      displayLayoutSelection,
      informational,
      embedded,
      navigationSliderBackgroundColor,
      navigationSliderColor,
      concatFields,
      amountOfFixedColumns,
      hideRowActionButtons,
      hideSortButtons,
      onChangeColumnsPage,
      amountOfActionColumns,
      customActionButtons
    }),
    [
      idObjectClass,
      objectclass,
      fields,
      actionColumns,
      actionColumnsPosition,
      columnsNames,
      objects,
      refetchObjects,
      columnsLayouts,
      onSort,
      sortOptions,
      currentColumnIndex,
      totalColumns,
      pagination,
      totalObjects,
      layoutClassificationUuid,
      hierarchyLevel,
      actionButton,
      selectedRows,
      setSelectedRows,
      mode,
      setMode,
      changedFieldValues,
      setChangedFieldValues,
      relationCounterpartField,
      reportDownloadTriggered,
      triggerReportDownload,
      reportUrl,
      objectclassQueryString,
      displayLayoutSelection,
      informational,
      embedded,
      navigationSliderBackgroundColor,
      navigationSliderColor,
      concatFields,
      amountOfFixedColumns,
      hideRowActionButtons,
      hideSortButtons,
      onChangeColumnsPage,
      amountOfActionColumns,
      customActionButtons
    ]
  )
}

export default useTableContextValue
