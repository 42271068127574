import { debounce } from 'lodash'
import { useCallback, useState } from 'react'

type UseCardAnimation = {
  onMoveTask: (
    taskUuid: string,
    newStatusColumn: string,
    setIsFadeInAnimationStarted: React.Dispatch<React.SetStateAction<boolean>>
  ) => void
  initialTaskParams?: {
    taskUuid: string
    newStatusColumn: string
  }
}

const useMoveTaskCardAnimation = ({ onMoveTask }: UseCardAnimation) => {
  const [targetCardUuid, setTargetCardUuid] = useState('')

  const onStartTaskMove = (taskUuid: string, newStatusColumn: string) => {
    onControlMoveTask(taskUuid, newStatusColumn)
  }

  const [isFadeOutAnimationStarted, setIsFadeOutAnimationStarted] =
    useState(false)

  const [isFadeInAnimationStarted, setIsFadeInAnimationStarted] =
    useState(false)

  const onControlMoveTask = useCallback(
    (taskUuid: string, newStatusColumn: string) => {
      setTargetCardUuid(taskUuid)

      setIsFadeOutAnimationStarted(true)

      debounce(() => {
        setIsFadeOutAnimationStarted(false)
        onMoveTask(taskUuid, newStatusColumn, setIsFadeInAnimationStarted)
      }, 800)()
    },
    [onMoveTask]
  )

  return {
    isFadeOutAnimationStarted,
    isFadeInAnimationStarted,
    targetCardUuid,
    onStartTaskMove
  }
}

export default useMoveTaskCardAnimation
