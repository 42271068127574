import { css } from 'styled-components'

export const getBackgroundImage = (leftImg?: string, rightImg?: string) => {
  if (leftImg && rightImg) {
    return `
      background-image: url(${leftImg}), url(${rightImg});
      background-position: left center, right center;
    `
  }

  if (leftImg || rightImg) {
    return `
      background-image: url(${leftImg || rightImg});
      background-position: ${leftImg ? 'left' : 'right'} center;
    `
  }

  return ''
}

export const getBaseBackgroundImageStyle = (imageUrl?: string) => {
  if (imageUrl) {
    return css`
      position: absolute;
      top: 0;
      bottom: 0;

      background-image: url(${imageUrl});
      background-size: contain;
      background-repeat: no-repeat;

      width: 175px;

      @media (min-width: 1920px) {
        width: 210px;
      }
    `
  }

  return ''
}
