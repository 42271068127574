import { useQuery } from '@tanstack/react-query'
import { FilterQuery } from '@yes.technology/react-toolkit'
import { SaF } from '@yes.technology/react-toolkit'
import apiAsync from 'infrastructure/shared/api/api-async'

type UseSaFQueryProps = {
  filterQuery?: FilterQuery
  objectclassUuid?: string
}

type SaFProps = Pick<React.ComponentProps<typeof SaF>, 'columns' | 'data'>

type DataResponse = {
  success: boolean
  columns?: SaFProps['columns']
  data?: {
    links?: SaFProps['data']['links']
    nodes?: SaFProps['data']['nodes']
  }
}

export const SAF_QUERY_REQUEST = {
  endpoint: 'msPlatform' as const,
  path: 'saf-chart' as const,
  method: 'POST' as const
}

export default function useSaFQuery({
  filterQuery,
  objectclassUuid
}: UseSaFQueryProps) {
  return useQuery<SaFProps>({
    queryKey: ['use-saf-query', filterQuery],
    queryFn: async () => {
      const result: DataResponse = await apiAsync.requestSync({
        ...SAF_QUERY_REQUEST,
        obj: {
          query: filterQuery,
          objectclass: objectclassUuid,
          pagination: {
            limit: 10,
            offset: 0
          },
          orderby: [
            {
              date_creation_row: 'desc'
            }
          ]
        }
      })

      const { columns = [], data } = result

      return {
        columns,
        data: {
          links: data?.links || [],
          nodes: data?.nodes || []
        }
      }
    },
    enabled: !!filterQuery
  })
}
