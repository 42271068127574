import {
  Icon,
  InformationCard,
  useIsMobile
} from '@yes.technology/react-toolkit'

import { useIsOpen } from 'shared/hooks'

import { HeroBannerSecondaryProps } from './HeroBannerSecondary.types'

import {
  Container,
  Title,
  Subtitle,
  Content,
  StyledButton,
  LeftImage,
  RightImage
} from './HeroBannerSecondary.styles'

const HeroBannerSecondary = ({
  des,
  complement,
  leftBackgroundImageUrl,
  rightBackgroundImageUrl,
  backgroundColor,
  textColor
}: HeroBannerSecondaryProps) => {
  const isMobile = useIsMobile()
  const { isOpen, open, close } = useIsOpen(false)

  return (
    <>
      <Container
        {...{
          backgroundColor
        }}
      >
        {!isMobile && <LeftImage {...{ leftBackgroundImageUrl }} />}

        <Content>
          <Title color={textColor}>{des}</Title>

          {isMobile ? (
            <StyledButton onClick={open} variant='secondary'>
              <Icon iconName='Information' />
            </StyledButton>
          ) : (
            <Subtitle color={textColor}>{complement}</Subtitle>
          )}
        </Content>

        {!isMobile && <RightImage {...{ rightBackgroundImageUrl }} />}
      </Container>

      {isMobile && (
        <InformationCard title={des as string} isOpen={isOpen} close={close}>
          {complement}
        </InformationCard>
      )}
    </>
  )
}

export default HeroBannerSecondary
