import { StatusObject } from '@yes.technology/react-toolkit'
import { useEffect } from 'react'

import { useQuery } from '@tanstack/react-query'
import fetchStatuses from '../api/fetchStatuses'

type UseStatusesArgs = {
  objectclassUuid?: string
}

export default function useStatuses({ objectclassUuid }: UseStatusesArgs) {
  const { data, error } = useQuery<StatusObject[] | null, Error>({
    queryKey: ['statuses', objectclassUuid],
    queryFn: () => fetchStatuses(objectclassUuid, false),
    enabled: !!objectclassUuid
  })

  useEffect(() => {
    if (error) console.log(error.message)
  }, [error])

  return data || []
}
