/* eslint-disable react/jsx-indent */
import { useTranslation } from 'react-i18n-lite'
import { useSearchParams } from 'react-router-dom'

import {
  Button,
  ExplicitSelectionButton,
  YesObject
} from '@yes.technology/react-toolkit'

import { concatenateFields } from 'shared/utils/object/concatenateFields'

import useSelection from '../hooks/useSelection'
import useTableContext from '../hooks/useTableContext'

import { DataTableSkeletonDots } from '../DataTable.styles'

import TableCell from './TableCell/TableCell'
import { FieldSettings } from 'shared/types'

export type TableRowProps =
  | {
      rowObject: YesObject
      insertionIndex?: never
      isLoading?: boolean
      fieldSettings?: FieldSettings
    }
  | {
      rowObject?: never
      insertionIndex: string
      isLoading?: boolean
      fieldSettings?: FieldSettings
    }

export default function TableRow({
  rowObject,
  insertionIndex,
  isLoading,
  fieldSettings
}: TableRowProps) {
  const {
    idObjectClass,
    columnsNames,
    actionColumns,
    actionColumnsPosition,
    pagination,
    totalObjects,
    sortOptions,
    objects,
    actionButton,
    mode,
    objectclassQueryString,
    informational,
    embedded,
    concatFields,
    hideRowActionButtons,
    customActionButtons
  } = useTableContext()

  const { onChangeSelection, getCheckedOption, getOption } = useSelection()

  const { t } = useTranslation()
  const objectId = rowObject?.uuid || insertionIndex || ''

  const [searchParams] = useSearchParams()
  const isUniselection = searchParams.has('uniselection')

  const showColumn =
    !embedded ||
    actionButton?.type === 'document' ||
    !informational ||
    !!customActionButtons

  const yesObject: YesObject = rowObject as YesObject

  const concatenatedFields = concatFields
    ? concatenateFields(concatFields, yesObject)
    : yesObject

  let actionCells = concatenatedFields
    ? (showColumn ? actionColumns : []).map(
        ({ title, component: ActionComponent }, index) => (
          <td className='text-center' key={title || index}>
            {isLoading ? (
              <Button variant='secondary' heightClass='small'>
                <DataTableSkeletonDots />
              </Button>
            ) : (
              <ActionComponent
                informational={informational}
                object={concatenatedFields}
                version={concatenatedFields.version}
                target={actionButton?.target}
                type={actionButton?.type}
                customActionButton={customActionButtons?.[objectId]}
                {...{
                  idObjectClass,
                  pagination,
                  totalObjects,
                  sortOptions,
                  objects,
                  objectclassQueryString,
                  mode
                }}
              />
            )}
          </td>
        )
      )
    : [
        <td key='view' className='text-center'>
          <Button disabled={!isLoading} variant='secondary' heightClass='small'>
            {isLoading ? <DataTableSkeletonDots /> : t('data-tables.view')}
          </Button>
        </td>
      ]

  const contentCells = columnsNames.map((columnName) => {
    const dateOptions = fieldSettings?.[columnName]?.date_options
    return isLoading ? (
      <td key={columnName}>
        <DataTableSkeletonDots />
      </td>
    ) : (
      <TableCell
        key={columnName}
        value={concatenatedFields ? concatenatedFields[columnName] : ''}
        columnName={columnName}
        objectUuidOrIndex={objectId}
        dateOptions={dateOptions}
      />
    )
  })

  const selectionCell = !informational && mode === 'view' && (
    <td className='text-center' key='selection-cell'>
      {isLoading ? (
        <DataTableSkeletonDots />
      ) : (
        <ExplicitSelectionButton
          id='selection-cell'
          heightClass='doubleXSmall'
          optionLabelsPosition='top'
          direction='horizontal'
          selectionType={isUniselection ? 'uniselection' : 'multiselection'}
          checkedOption={getCheckedOption(concatenatedFields?.uuid || '')}
          options={concatenatedFields ? [getOption(concatenatedFields)] : []}
          required={false}
          onChange={onChangeSelection}
        />
      )}
    </td>
  )

  actionCells = hideRowActionButtons ? [] : actionCells

  return (
    <tr key={objectId}>
      {actionColumnsPosition === 'first'
        ? [selectionCell, ...actionCells, ...contentCells]
        : [selectionCell, ...contentCells, ...actionCells]}
    </tr>
  )
}
